import styled from 'styled-components';
import media from 'styled-media-query';
import { defaultTheme } from '../../styles/theme';

import Wide from '../../assets/img/404Wide.svg';
import Web from '../../assets/img/404web.svg';
import IPad from '../../assets/img/404ipad.svg';
import Mobile from '../../assets/img/404mobile.svg';

export const Container = styled.div`
  background-image: url(${Wide});
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${media.lessThan('1925px')`
    background-image: url(${Web});
    `};

  ${media.lessThan('1025px')`
    background-image: url(${IPad});
    `};

  ${media.lessThan('767px')`
    background-image: url(${Mobile});
    `};
`;

export const Button = styled.button`
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 20px;
  width: 180px;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: ${defaultTheme.colors.white};
  transition: all 0.7s ease;

  :hover {
    color: ${defaultTheme.colors.white};
    background-color: ${defaultTheme.colors.red};
    transition: all 0.7s ease;

    img {
      filter: invert();
      transition: all 0.7s ease;
    }
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
  img {
    display: flex;
    align-items: flex-start;
    width: 30px;
    margin: 0 5px;
  }
`;
