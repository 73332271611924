import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React from 'react';
import RETURN from '../../assets/icon/returnArrowIcon.svg';
import { PageWrapper } from '../../components';
import * as S from './styled';

const NotFound = () => (
  <>
    <S.Container />
    <AniLink to="/">
      <S.Button type="button">
        <img
          src={RETURN}
          alt="Seta curvada para esquerda, indicando o símbolo de retorno"
          loading="lazy"
          decoding="async"
        />
        <p>Return to Home</p>
      </S.Button>
    </AniLink>
  </>
);

export default PageWrapper(NotFound);
